@font-face {
  font-family: "SFPro";
  src: local("SFPro"),
    url(../assets/fonts/sf-pro/SFNSDisplay.woff2) format("woff2");
  font-weight: 100 1000;
}

@font-face {
  font-family: "SFProRounded";
  src: local("SFProRounded"),
    url(../assets/fonts/sf-pro/SFNSRounded.woff2) format("woff2");
  font-weight: 100 1000;
}

ion-tab-bar {
  will-change: height;
  position: fixed !important;
  bottom: 0px;
  width: 100%;

  /* pointer-events: none;
  opacity: 0; */
}
ion-content {
  --padding-bottom: 60px !important;
}
